import { ReactComponent as InflowLogoFull } from "../../icons/InflowLogoFull.svg";
import { LOGOUT_CLICKED } from "../../constants/actions";
import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import useUserData from "../../custom-hooks/useUserData";
import utils from "../../utils";

const SHOW_NAV_FOR = new Set(["clinic", "account"]);
const HIDE_NAV_FOR_PATHS = new Set(["/clinic/payment"]);

const NavBar = (): ReactElement => {
  const dispatch = useDispatch();
  const locationRoot = location.pathname.split("/")[1];

  const { isLoggedIn } = useUserData();

  const showNavBar =
    !utils.isUserOnApp() &&
    locationRoot != null &&
    SHOW_NAV_FOR.has(locationRoot) &&
    !HIDE_NAV_FOR_PATHS.has(window.location.pathname);

  const handleLogOut = () => {
    dispatch({ type: LOGOUT_CLICKED });
    utils.logOut();
  };

  return showNavBar ? (
    <nav
      className={`mx-auto max-w-7xl flex h-20 px-5 justify-between items-center "flex"
      }`}
    >
      <InflowLogoFull className="w-28" />

      {isLoggedIn && (
        <button className="text-sapphireBlue font-bold" onClick={handleLogOut}>
          Log out
        </button>
      )}
    </nav>
  ) : (
    <></>
  );
};

export default NavBar;
