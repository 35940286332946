// please add newest flags at the top of the list

export const flagIds = [
  "tailored-value-prop",
  "ten-minute-timer",
  "timer-on-checkout",
  "custom-toolkit",
  "pricing-remix",
  "personalized-demographic",
  "personal-story",
  "user-insight-popup",
  "paywall-before-results",
  "pre-pricing-benefits",
  "dedupe-account-funnel-changes",
  "pre-pricing-exclusion",
  "pricing-page-tests",
  "apple-pay-modal",
  "upfront-payments",
  "share-results",
  "localized-currency",
  "paypal-payments",
  "program-pricing",
  "show-quiz-error",
  "illustration-question-lp",
  "emoji-question-lp",
  "growthbook-aa-test",
  "growthbook-aa-test-continue",
] as const;

// DevMenu only
// if a flag is NOT in this list, variant will display as a text input
export const flagVariants: Record<string, string[]> = {
  "tailored-value-prop": ["control", "tailored-value-prop"],
  "ten-minute-timer": ["control", "ten-minute-timer"],
  "timer-on-checkout": ["control", "timer-on-checkout"],
  "custom-toolkit": ["control", "custom-toolkit"],
  "user-insight-popup": [
    "control",
    "price-options",
    "result-adhd-calculations",
  ],
  "paywall-before-results": ["control", "paywall-before-results"],
  "pre-pricing-benefits": ["control", "pre-pricing-benefits"],
  "pre-pricing-exclusion": [
    "control",
    "we-know-adhd-exclusion",
    "getting-help-exclusion",
    "trial-timeline-exclusion",
    "all-exclusion",
  ],
  "pricing-page-tests": [
    "control",
    "trial-focus",
    "benefits-focus",
    "design-refresh",
  ],
  "apple-pay-modal": [],
  "upfront-payments": ["control", "upfront-v3-2options", "upfront-v3-3options"],
  "share-results": [],
  "localized-currency": ["control", "localized"],
  "paypal-payments": [],
  "program-pricing": [],
  "show-quiz-error": [],
  "illustration-question-lp": [
    "control",
    "productive-question-lp",
    "​priority-question-lp",
  ],
  "emoji-question-lp": ["control", "feeling-question-lp", "putoff-question-lp"],
  "personal-story": ["control", "personal-story"],
  "personalized-demographic": ["control", "gender-question-lp"],
  "pricing-remix": ["control", "pricing-remix"],
  "growthbook-aa-test": ["control", "growthbook-aa-test"],
  "growthbook-aa-test-continue": ["control", "growthbook-aa-test-continue"],
} as const;

export type FlagId = (typeof flagIds)[number];
